import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import {Modal} from 'react-bootstrap';
import { hairdresserActions,paymentActions,bookingActions } from '../../_actions';
import {paymentService} from "../../_services";
import ConfirmBookingPopup from "../hairdresser/ConfirmBookingPopup"
import siteSetting from '../../config/env';
import {Addicon, BackArrow, Close, MySpinner} from '../SvgIcon';
import { bookingConstants, settingConstants, } from "../../_constants";
import Skeleton from 'react-loading-skeleton';
import { Spinner } from 'react-bootstrap';
import { SalonInfoLoader } from './SkletonLoader';
import queryString from "query-string";


var _ = require('lodash');
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
var _ = require('lodash');

class SalonInfo extends Component {
    constructor(props) {
      super(props);
      this.state={
          error: {},
          message: '',
          loader: true,
          currentLocation: {},
          latitude: siteSetting.default_latitude,
          longitude: siteSetting.default_longitude,
          images:[],
          salon:{},
          loading:false,
          PaymentFailedPopup:false,
          bookingConf:false,
          prevUserId:null,
       }

       this.closeModal = this.closeModal.bind(this);
       this.closePopUp = this.closePopUp.bind(this);
       this.openPaymentFailedPopup = this.openPaymentFailedPopup.bind(this);
       this.toggleBookingConf = this.toggleBookingConf.bind(this);
    }


    closeModal() {
      this.setState({
          PaymentFailedPopup:false
      })
  }

  closePopUp() {
    this.props.dispatch({ type: settingConstants.TOGGLE_SERVICE_POP, value: 2 });
}
   
    async componentDidMount() {
      let self=this;

        if(this.props.match.path=="/book/:hairdresserId"){
            let params={};
            let hairdresserId = this.props.match.params.hairdresserId;
            params.hairdresserId = hairdresserId;
            params.latitude = siteSetting?.default_latitude;
            params.longitude = siteSetting.default_longitude;
            await self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
                .then(function (response) {
                    if (response.data.errorCode) {
                        self.setState({
                            error: response.data.error.messages
                        })
                    } else {
                        console.log("response.data",response?.data?.data?.userInfo?.hairdresser?.salon?._id);
                        if(response?.data?.data?.userInfo?.hairdresser?.salon?._id){
                            let salonId=response?.data?.data?.userInfo?.hairdresser?.salon?._id;
                            window.location.assign('/salon/'+salonId+'/'+hairdresserId+'/service');
                        }else{
                            window.location.assign('/');
                        }
                    }
                });
        }

      self.setState({
        prevUserId:sessionStorage.getItem('prevUserId')
      })

     if(self?.props?.location?.state?.prevUserId){
        sessionStorage.setItem('prevUserId',self?.props?.location?.state?.prevUserId);
     }else{
        sessionStorage.removeItem('prevUserId');
     }
      
      console.log('check Data',self?.props?.location?.state?.prevUserId);

      document.body.classList.add('no-topbar');
      this.getHairdressers();
      if(this.props.match.path=="/salon/:salonId/professional/:hairdresserId/success"){
          let params = queryString.parse(self.props.history.location.search);
          console.log("params",params);
          let bookingId=params?.bid;
          let transactionId=params?.tid;
        // if(localStorage.getItem('bookingId')&&localStorage.getItem('bookingId')!=="") {
            if(bookingId&&bookingId!=="") {
            let getParams = {
                // bookingId: localStorage.getItem('bookingId'),
                bookingId: bookingId,
                getBookingsOfType: 'upcoming',
                deviceToken: 'sfsadfsdfds',
                error: ''
            }

            self.props.dispatch(bookingActions.getBooking(getParams))
                .then(function (response) {

                })
            let paymentData={
                // transactionId: localStorage.getItem('transactionId'),
                transactionId:transactionId
            }

            let sessionId=params?.session_id;

          if(sessionId&&sessionId!==""){
            self.props.dispatch(paymentActions.makeCustomerPrimaryCard({bookingId:getParams?.bookingId,sessionId:sessionId})).then(res=>{
               console.log('check updated booking id',res);
                self.toggleBookingConf();
            })
          }else{
              if(paymentData.transactionId&&paymentData.transactionId!=="") {
                  self.props.dispatch(paymentActions.bookingPaymentDane(paymentData))
                      .then(function (response) {
                          self.toggleBookingConf();
                      })
              }else{
                  self.toggleBookingConf();
              }
          }

          
            self.setState({
                paymentType:"Paid Online"
            })
           
            // localStorage.removeItem('bookingId');
        }
    }
      if(this.props.match.path=="/salon/:salonId/professional/:hairdresserId/fail"){
        self.setState({
            PaymentFailedPopup:true
        })
    }
  }


  toggleBookingConf(slug='') {
    localStorage.removeItem('isBooking');
    // localStorage.removeItem('bookingId');
    let self = this;
    let params = {}
    this.props.dispatch({ type: settingConstants.TOGGLE_CONF_BOOKING });
    let hairdresserId = this.props.match.params.hairdresserId;
    params.hairdresserId = hairdresserId;
    params.latitude = siteSetting?.default_latitude;
    params.longitude = siteSetting.default_longitude;
    self.props.dispatch(hairdresserActions.getHairdresserInfoWeb(params))
        .then(function (response) {
            if (response.data.errorCode) {
                self.setState({
                    error: response.data.error.messages
                })
            } else {
                self.setState({
                    bookingConf:!self.state.bookingConf,
                })
                
                if(slug=='close'){
                    localStorage.removeItem('bookingId')
                    window.location.assign('/');
                }
            
            }
        });
}


  getHairdressers(){
    let params = {};
    let self = this;
    let salonId=this.props.match.params.salonId;
    let hairdresserId=this.props.match.params.hairdresserId;
    params.salonId = this.props.match.params.salonId
    params.latitude =  siteSetting.default_latitude;
    params.longitude =  siteSetting.default_longitude;
    self.setState({loading:true})
    self.props.dispatch(hairdresserActions.getSalonProfile(params))
    .then(function (response) {
      if (response.data.errorCode) {
          self.setState({
              error: response.data.messages
          })
      }
        if(!response.data.data.salons){
            self.props.history.push("/404");
        }
        if(!hairdresserId){
            self.props.history.push('/salon/'+salonId+'/professional/'+response.data.data.salons.hairdresser[0]._id);
        }
      self.setState({loading:false}) 
  })
  }

  openPaymentFailedPopup() {
    this.setState({
        PaymentFailedPopup: !this.state.PaymentFailedPopup
    })
}

 gotoStaffPage(){
        let salonId = this.props.match.params.salonId;
        sessionStorage.setItem('prevUserId',this.props.match.params.hairdresserId);
        let isArrow =this.props.history.location?.state?.isBackArrow?this.props.history.location?.state?.isBackArrow:false;
        this.props.history.push({
            pathname: `/salon/${salonId}/staff`,
            state: {
              prevUrl:this.props.history.location.pathname,
              ...this.props.history.location?.state
            },
          }); 
    }


  
    render(){
      let { salon ,salonList} = this.props;
      let{loading}= this.state
      let salonId = this.props.match.params.salonId;
      //const addressDetailsObj = salonList?.hairdressers?.filter(hairdresser => hairdresser.hairdresser.salon._id===salonId)
      //console.log("addressDetailsObj",addressDetailsObj,salonList);
      const addressDetails=salon?.salonInfo?.salons?.formattedAddress
      const salonName =salon?.salonInfo?.salons?.salonName
      return (
        <>
        {loading ? <SalonInfoLoader/>
        :
        <div className='home_main'>
        {((this?.state?.prevUserId!=null&&this?.state?.prevUserId!=undefined) && this?.props?.history?.location?.state?.marketPlaceUrl!=undefined)?<div 
          className='back-arrow-image'
          onClick={()=>{
            this.props.history.push({
              pathname:this.props?.history?.location?.state?.marketPlaceUrl,
              state:{...this.props.history.location?.state}
          })}}
        >
          <BackArrow/>
        {/* <img alt="img" src={require('../../assets/images/down-arrow-icon1.png')} className="img-fluid client-booking-back-arrow" /> */}
        </div>:null}
          <div className='__left banner_image'>
            {loading ? <div style={{width:'200px',height:'200px',background:'pink'}}><Spinner/></div>
            :<img alt={'salon-banner'} src={salon?.salonInfo?.salons?.image ? salon?.salonInfo.salons.image : require('../../assets/images/salonpic.png')} className="img-fluid "/>
            }
           
             {/* <div className='mobile_logo hide-desktop'>
                <img alt={'salon-logo'}  src={require('../../assets/images/barbar-shop.png')} className="img-fluid" />
              </div> */}
          </div>
          <div className='__right text_parent'>
            <div className='beu_logo hide-mobile'>
            <img alt={'salon-logo'} src={require('../../assets/images/beu-logo-rectangle.png')} className="img-fluid mb-3" />
            </div>
            <div className='__bottom'>
          
               {/* <div className='client_logo'>
                  <div className='logo hide-mobile'>
                  <img alt={'salon-logo'} src={require('../../assets/images/barbar-shop.png')} className="img-fluid mb-3" />
                  </div>
                </div> */}

                <h2 className='hide-mobile'>{salonName}</h2>
                <p>{addressDetails}</p>
                <button onClick={()=>this.gotoStaffPage()} className={`btn __black_btn px-5 mt-5`} >
                Book Now</button>
            </div>
          </div>
        </div>
        }

        <Modal blurClass="blure_bg"
        contentClassName="p-0 payment-failed"
        show={this.state.PaymentFailedPopup}>
           <div className="cm_modal booking_bg popupscroll ">
                    <div className="modal-header closesvg" style={{ borderBottom: 'none' }}>
                        <p className="text-right w-100 " style={{ cursor: 'pointer' }} onClick={()=>this.closeModal()}><Close /></p>
                    </div>
                <div className="p-5 text-center">
                <img src={require('../../assets/images/paymentfailed.png')} className="img-fluid mb-3" />
                <h3 style={{fontSize: '38px', fontWeight: '200'}} className="mb-4">Uh-Oh</h3>
                <p style={{fontWeight: '200'}}>Something Happened, but Don't worry you haven't been charged. Please try again</p>

                <div className="stap-action mt-5">
                    <button className="btn btn-info btn-block" onClick={()=>this.openPaymentFailedPopup()}>Continue</button>
                </div>
                </div>
                </div>
            </Modal>
            <Modal show={this.state.bookingConf} blurClass="blure_bg" centered dialogClassName="hide_gray_content_bg">
            {this.props.booking.bookings?
                <ConfirmBookingPopup bookingState={this.state} slug={'close'} toggleBookingConf={this.toggleBookingConf}/>
                :""}
            </Modal>
        </>
      
      );
  }
};


function mapStateToProps(state) {
  const {
      hairdresser, salon, user,
      booking,
      createBooking,
      salonList
  } = state;
  return {
      hairdresser,
      salon,
      user,
      booking,
      salonList,
      createBooking
  };
}

export default connect(mapStateToProps)(SalonInfo);

