import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
// import axios from 'axios';
import { createBrowserHistory } from 'history';

import {PrivateRoute} from "./routing/PrivateRoute";
import { Capacitor } from '@capacitor/core';
import HairdresserComponent   from "./component/hairdresser/Hairdresser";
import Dashboard              from "./component/Dashboard/Dashboard";
// import Header                 from "./component/template/Header";
import Pagenotfound           from "./component/template/Pagenotfound";
import Reset                  from "./component/template/Reset";
import Homepage               from "./component/home/HomePage";
// import NewBooking             from "./component/hairdresserBooking/NewBooking";
import NewBooking1             from "./component/newBooking/NewBooking";
import NewEvent             from "./component/hairdresserEvent/NewEvent";
import InstantCheckout             from "./component/checkoutBooking/InstantCheckout";
import Clients                from "./component/clients/Clients";

import Analytics              from "./component/hairdresserAnalytics/AnalyticsDashboard";

import './App.css';
import './component/newSalon/salon.css'
import AddClient from './component/clients/AddClient';
import EditClient from './component/clients/EditClient';
import ClientDetail from './component/clients/ClientDetail';
import Staff from './component/staff/Staff';
import AddStaff from './component/staff/AddStaff';
import EditStaff from './component/staff/EditStaff';
import Services from './component/services/Services';
import NewServices from './component/new-service/NewServices';

import AddServices from './component/new-service/add-edit-service/AddService';
import EditServices from './component/new-service/add-edit-service/EditService';

import AddService from './component/services/AddService';
import SalonSettings from './component/settings/SalonSettings';
import EditPassword from './component/settings/EditPassword';
import EditBankAccount from './component/settings/EditBankAccount';
import Signup from './component/hairdresserAuth/Signup';
import GoThroughModalAfterLogin from './component/hairdresserAuth/GoThroughModalAfterLogin';
import Steps from './component/hairdresserAuth/Steps';
import BookingInvoice from './component/Invoice/Booking'
// import HairdressersHome from './component/hairdresserBooking/HairdressersHome';
// import JoinForFree from './component/hairdresserAuth/JoinForFree';
import TermsAndConditions from './component/hairdresserAuth/TermsAndConditions';
import PrivacyPolicy from './component/hairdresserAuth/PrivacyPolicy';
import HairdresserTermsAndConditions from './component/hairdresserAuth/HairdresserTermsAndConditions';
import ProfileSettings from './component/settings/ProfileSettings';
// import AcountSettings from './component/settings/AcountSettings';
import ComingSoon from './component/ComingSoon';
// import DiaryPage from './component/Diary/DiaryPage';
import NewDiary from './component/Diary/NewDiary';
// import Booking from './component/booking/Booking';
import ViewBooking from './component/booking/ViewBooking';
import Event from './component/events/Event';
import ClearCache from "react-clear-cache";
// import EditBooking from "./component/bookingEdit/EditBooking";
import NewEditBooking from "./component/bookingEdit/NewEditBooking";

// import checkoutBooking from "./component/checkoutBooking/checkoutBooking";

import WebHeader from "./component/template/webHeader";
import Home from "./component/webHome/Home";
import Redirection from "./component/webHome/Redirection";
import Payment from "./component/payment/payment";
// import NewDiary1 from "./component/Diary/NewDiary1";
// import DiaryResource from "./component/Diary/DiaryResource";
import BookingUrl from './component/settings/BookingUrl';
import AccountPassword from './component/settings/AccountPassword';
// import SettingSidebar from './component/settings/SettingSidebar';
import PayoutAccount from './component/settings/PayoutAccount';
import CardReader from './component/settings/CardReaders';
import AddPayoutAccount from './component/settings/AddPayoutAccount';
import SettingMobileView from './component/settings';
import Product from './component/product/Product'
import AddProduct from './component/product/AddProduct'
import EditProduct from './component/product/EditProduct'
import DeleteProduct from './component/product/DeleteProduct'
import ViewProduct from './component/product/ViewProduct'
// import ProductCheckout from './component/product/ProductCheckout'
import BookingCheckout from './component/checkoutBooking/BookingCheckout';
import SelectClient from './component/clients/SelectClient';
import SelectProduct from './component/product/SelectProduct';
// import SelectService from './component/services/SelectService';
import NewSelectService from './component/services/NewSelectService';
import SalonInfo from './component/newSalon/SalonInfo';
import StaffWrapper  from './component/newSalon/staff/StaffWrapper';
import NewService from './component/newSalon/services/Services';
import ServiceMobileView from './component/new-service/index';
import BookingSlots from './component/newSalon/bookingSlots/bookingSlots';
import Marketing from './component/marketing/marketing';
import UpdateReminder from './component/marketing/updateReminder';
import UpdateBooking from './component/marketing/updateBooking';
import UpdateSales from './component/marketing/updateSales';
import ReviewBooster from './component/marketing/reviewBooster';
import Preview from './component/marketing/preview';
import EmailPreview from './component/marketing/EmailPreview';
import CreateReminder from './component/marketing/createReminder';
import UpdateCustomReminder from './component/marketing/updateCustomReminder';
import CreateCustomReminder from './component/marketing/createCustomReminder';
import MarketingMobileView from './component/marketing';
import MarketingSettings from './component/marketing/settings';
import Billing from './component/settings/Billing';
import NewCustomReminder from './component/marketing/newReminder';

export const history = createBrowserHistory();

let {isLatestVersion, emptyCacheStorage} = ClearCache

class Routes extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    history.listen((location, action) => {
         
      
    });
    this.navigateRef = React.createRef();
  
  }


componentDidMount(){
  // console.log("dfdfdfuhikkk",this.navigateRef.current.className,history.location.pathname)

  if(this.navigateRef?.current?.className=='native-platform'){
    document.body.classList.add('native-platform-modal')
  }
  // console.log("ttttttt",this.props)
}

  render() {
    
    let accessToken = localStorage.getItem('accessToken');
    //console.log("######accessToken######",accessToken);
      if(accessToken && accessToken!=="") {
          //axios.defaults.headers.common['Authorization'] = `${'Bearer ' + accessToken}`;
      }
 // console.log("##$$", Capacitor.isNativePlatform());

    return (
        <Router history={history}>
           <div ref={this.navigateRef} className={Capacitor.isNativePlatform()===true?'native-platform' : ''}>
          
            <ClearCache auto={true}>
            {({ isLatestVersion}) => (
            <>
            {console.log( "isLatestVersion # ",isLatestVersion,localStorage.getItem('APP_VERSION'))}
          {/* <Header history={history}/> */}
          <WebHeader history={history}/>
          <div className="fade modal-backdrop showw"></div>
          <div className="bodyContainer ">
         
            <Switch>
                <Route exact path="/" component={Homepage}  key={1} />
                <Route path="/social-login" component={Homepage}/>
                <Route path="/login" component={Homepage}/>
                <Route path="/redirect" component={Homepage}/>
                <Route path="/reset/:token" component={Reset} />
                {/*<Route exact path="/salon/:salonId" component={HairdresserComponent} />*/}
                {/*<Route exact path="/old-salon/:salonId/professional/:hairdresserId" component={HairdresserComponent} />*/}
                {/*<Route exact path="/old-salon/:salonId/professional/:hairdresserId/success" component={HairdresserComponent} />*/}
                {/*<Route exact path="/old-salon/:salonId/professional/:hairdresserId/fail" component={HairdresserComponent} />*/}
                <Route exact path="/salon/:salonId" component={SalonInfo} />
                <Route exact path="/salon/:salonId/staff" component={StaffWrapper} />
                <Route exact path="/salon/:salonId/:hairdresserId/service" component={NewService} />
                <Route exact path="/salon/:salonId/:hairdresserId/booking-slot" component={BookingSlots} />
                <Route exact path="/salon/:salonId/professional/:hairdresserId" component={SalonInfo} />
                <Route exact path="/salon/:salonId/professional/:hairdresserId/success" component={SalonInfo} />
                <Route exact path="/salon/:salonId/professional/:hairdresserId/fail" component={SalonInfo} />

                <Route exact path="/book/:hairdresserId" component={SalonInfo} />

                {/*<Route exact path="/diary-old/:salonId" component={DiaryPage} />*/}
                <Route exact path="/diary/:salonId" component={NewDiary} key={`radha`}/>
                {/* <Route exact path="/diary/:salonId" render={()=>(<NewDiary hi="clsname"/>)} /> */}
              {/*<Route exact path="/diary1/:salonId" component={DiaryResource} />*/}
              {/*<Route exact path="/newdiary1/:salonId" component={NewDiary1} />*/}
                <Route exact path="/event/:salonId" component={NewEvent} />
                <Route exact path="/newbooking/:salonId" component={NewBooking1} />
                <Route exact path="/newbooking/:salonId/:hairdresserId" component={NewBooking1} />
                {/*<Route exact path="/old-booking/:salonId" component={NewBooking} />*/}
                {/*<Route exact path="/old-booking/:salonId/:hairdresserId" component={NewBooking} />*/}
                <Route exact path="/new-checkout/:salonId" component={InstantCheckout} />
                <Route exact path="/clients" component={Clients} />
                <Route exact path="/clients/add" component={AddClient} />
                <Route exact path="/clients/booking/add" component={AddClient} />
                <Route exact path="/clients/:id" component={ClientDetail} />
                <Route exact path="/clients/edit/:id" component={EditClient} />
                <Route exact path="/transactions" component={Analytics} />
                <Route exact path="/staff" component={Staff} />
                <Route exact path="/staff/rota" component={Staff} />
                <Route exact path="/staff/add" component={AddStaff} />
                <Route exact path="/staff/:id" component={EditStaff} />
                <Route exact path="/services" component={Services} />


                <Route exact path="/new-services" component={NewServices} />
                <Route exact path="/add-services" component={AddServices} />
                <Route exact path="/edit-services/:id" component={EditServices} />
                <Route exact path="/service-nav" component={ServiceMobileView} />
                
                <Route exact path="/marketing" component={Marketing} />
                <Route exact path="/marketing-nav" component={MarketingMobileView} />
                <Route exact path="/marketing-custom-new" component={NewCustomReminder} />
                <Route exact path="/marketing/reminder-update" component={UpdateReminder} />
                <Route exact path="/marketing/custom-reminder-update" component={UpdateCustomReminder} />
                <Route exact path="/marketing/booking-update" component={UpdateBooking} />
                <Route exact path="/marketing/sales-update" component={UpdateSales} />
                <Route exact path="/marketing/review-booster" component={ReviewBooster} />
                <Route exact path="/marketing/create-reminder" component={CreateReminder} />
                <Route exact path="/marketing/create-custom-reminder" component={CreateCustomReminder} />
                <Route exact path="/marketing-setting" component={MarketingSettings} />
                <Route exact path="/settings/billing" component={Billing} />
                
                <Route exact path="/preview" component={Preview} />
                <Route exact path="/email-preview" component={EmailPreview} />
                <Route exact path="/services/:hairdresserId" component={Services} />
                <Route exact path="/services/:hairdresserId/:serviceId" component={AddService} />
                <Route exact path="/settings/salon-details" component={SalonSettings} />
                <Route exact path="/settings" component={SettingMobileView} />
                <Route exact path="/settings/booking-url" component={BookingUrl} />
                <Route exact path="/settings/edit-password" component={EditPassword} />
                <Route exact path="/settings/edit-bank-account" component={EditBankAccount} />
                <Route exact path="/settings/profile" component={ProfileSettings} />
                {/*<Route exact path="/settings/account" component={AcountSettings} />*/}
                <Route exact path="/settings/add-payout-account" component={AddPayoutAccount} />
                <Route exact path="/settings/account-password" component={AccountPassword} />
                <Route exact path="/settings/payout-account" component={PayoutAccount} />
                <Route exact path="/settings/card-reader" component={CardReader} />
                <Route exact path="/event" component={Event} />
                {/*<Route exact path="/join" component={JoinForFree} />*/}
                <Route exact path="/event-view/:eventId" component={Event} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/steps" component={Steps} />
                <Route exact path="/steps-after-login" component={GoThroughModalAfterLogin} />
                {/*<Route exact path="/hairdressers-home" component={HairdressersHome}/>*/}

                {/*<PrivateRoute path='/booking/:bookingId' component={Booking} />*/}
                <PrivateRoute path='/booking/:bookingId' component={ViewBooking} />
                {/*<PrivateRoute path='/booking-edit/:bookingId' component={EditBooking} />*/}
                <PrivateRoute path='/new-booking-edit/:bookingId' component={NewEditBooking} />
                
                {/* <PrivateRoute path='/booking-checkout/:bookingId' component={checkoutBooking} /> */}
                <PrivateRoute path='/checkout/:bookingId' component={BookingCheckout} />
                <PrivateRoute path='/select-client/:hairdresserId/:indexId' component={SelectClient} />
                {/* <PrivateRoute path='/product/BookingCheckout/:bookingId' component={BookingCheckout} /> */}

                <PrivateRoute path='/invoice/:bookingId' component={BookingInvoice} />
                {/*<PrivateRoute path='/review' component={Dashboard} />*/}
                <Route path='/booking' component={Dashboard} />
                <PrivateRoute path='/booking-detail/:bookingId' component={Dashboard} />
                <PrivateRoute path='/myaccount' component={Dashboard} />
                <PrivateRoute path='/legal' component={Dashboard} />

                <Route path="/terms-and-conditions" component={TermsAndConditions}/>
                <Route path="/privacy-policy" component={PrivacyPolicy}/>
                <Route path="/hairdresser-terms-and-conditions" component={HairdresserTermsAndConditions}/>

                <Route exact path="/redirection/price/:id/plan/:id" component={Redirection}  />
                <Route exact path="/get-software" component={Home}  />
                <Route exact path="/payment" component={Payment}  />


                <Route exact path="/product" component={Product} />
                <Route exact path="/product/addProduct" component={AddProduct} />
                <Route exact path="/product/editProduct" component={EditProduct} />
                <Route exact path="/product/viewProduct" component={ViewProduct} />
                <Route exact path="/product/deleteProduct" component={DeleteProduct} />
                <Route exact path="/product/list-product" component={Product} />
                {/*<Route exact path="/product/productCheckout" component={ProductCheckout} />*/}
                <Route exact path="/selectProduct/:salonId/:indexId" component={SelectProduct} />
                {/*<Route exact path="/selectService" component={SelectService} />*/}
                <Route exact path="/new-selectService/:hairdresserId/:indexId" component={NewSelectService} />
                <Route path='/coming-soon' component={ComingSoon}  />
                <Route path='/404' component={Pagenotfound}  />
                <Route path='*' component={Pagenotfound} status={404} />

            </Switch>
          </div>
                    </>
                    )}
            </ClearCache>
            </div>
        </Router>
    );
  }
}


function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedApp = connect(mapStateToProps)(Routes);
export { connectedApp as Routes };





